import React, { useState } from "react";
import "./ServicesPage.css"; // Import the CSS styles for this page
import Footer from '../components/Footer';

const services = [
  {
    category: "SMS SERVICES",
    services: [
      {
        name: "Transactional SMS",
        options: [
          { label: "10,000 @ 0.16 paisa", value: "10000 @ 0.16" },
          { label: "50,000 @ 0.15 paisa", value: "50000 @ 0.15" },
          { label: "1,00,000 @ 0.14 paisa", value: "100000 @ 0.14" },
          { label: "5,00,000 @ 0.13 paisa", value: "500000 @ 0.13" },
          { label: "10,00,000 @ 0.12 paisa", value: "1000000 @ 0.12" },
        ],
        link: "/Payments",
      },
      {
        name: "OTP SMS",
        options: [
          { label: "10,000 @ 0.22 paisa", value: "10000 @ 0.22" },
          { label: "50,000 @ 0.15 paisa", value: "50000 @ 0.15" },
          { label: "1,00,000 @ 0.14 paisa", value: "100000 @ 0.14" },
          { label: "5,00,000 @ 0.13 paisa", value: "500000 @ 0.13" },
          { label: "10,00,000 @ 0.12 paisa", value: "1000000 @ 0.12" },
        ],
        link: "/Payments",
      },
      {
        name: "Voice SMS",
        options: [
          { label: "10,000 @ 0.28 paisa", value: "10000 @ 0.28" },
          { label: "50,000 @ 0.26 paisa", value: "50000 @ 0.26" },
          { label: "1,00,000 @ 0.22 paisa", value: "100000 @ 0.22" },
          { label: "5,00,000 @ 0.20 paisa", value: "500000 @ 0.20" },
          { label: "10,00,000 @ 0.18 paisa", value: "1000000 @ 0.18" },
        ],
        link: "/Payments",
      },
      {
        name: "WhatsApp API",
        options: [
          { label: "10,000 @ 0.35 paisa", value: "10000 @ 0.35" },
          { label: "50,000 @ 0.33 paisa", value: "50000 @ 0.33" },
          { label: "1,00,000 @ 0.32 paisa", value: "100000 @ 0.32" },
          { label: "5,00,000 @ 0.30 paisa", value: "500000 @ 0.30" },
          { label: "10,00,000 @ 0.28 paisa", value: "1000000 @ 0.28" },
        ],
        link: "/Payments",
      },
    ],
  },
  {
    category: "DIGITAL SERVICES",
    services: [
      { name: "Social Media Marketing (SMM)", link: "/social-media-marketing" },
      { name: "Social Media Optimization (SMO)", link: "/social-media-optimization" },
      { name: "Social Media Page Creation (SPC)", link: "/social-media-page-creation" },
      { name: "SEO Services", link: "/seo-services" },
      { name: "Google Analytics", link: "/google-analytics" },
      { name: "WhatsApp Verified Services", link: "/whatsapp-verified-services" },
      { name: "Facebook Promotion", link: "/facebook-promotion" },
      { name: "Instagram Promotion", link: "/instagram-promotion" },
      { name: "YouTube Promotion", link: "/youtube-promotion" },
      { name: "Facebook Ads Setup", link: "/facebook-ads-setup" },
      { name: "Instagram Ads Setup", link: "/instagram-ads-setup" },
      { name: "Google Ads Setup", link: "/google-ads-setup" },
    ],
  },
  {
    category: "WEBSITE DESIGN",
    services: [
      { name: "Static Web Design", link: "/static-web-design" },
      { name: "Dynamic Web Design", link: "/dynamic-web-design" },
      { name: "WordPress Web Design", link: "/wordpress-web-design" },
      { name: "E-Commerce Web Design", link: "/ecommerce-web-design" },
      { name: "E-commerce Customize", link: "/ecommerce-customize" },
      { name: "Logo Design", link: "/logo-design" },
      { name: "Responsive Logo", link: "/responsive-logo" },
      { name: "3D Logos", link: "/3d-logos" },
      { name: "Mailer Design", link: "/mailer-design" },
      { name: "Responsive Mailer", link: "/responsive-mailer" },
      { name: "Mailer Redesign", link: "/mailer-redesign" },
    ],
  },
  {
    category: "EMAIL MARKETING",
    services: [
      { name: "Google Mail (Gsuite)", link: "/google-mail" },
      { name: "Microsoft Office 365", link: "/microsoft-office" },
      { name: "Premium Business Mails", link: "/premium-business-mails" },
    ],
  },
  {
    category: "OTHER SERVICES",
    services: [
      { name: "Toll Free", link: "/toll-free" },
      { name: "IVR Number", link: "/ivr-number" },
      { name: "Miss Call Alert", link: "/miss-call-alert" },
      { name: "SMS Leads", link: "/sms-leads" },
      { name: "Email Leads", link: "/email-leads" },
      { name: "Facebook & Instagram Leads", link: "/facebook-instagram-leads" },
    ],
  },
  {
    category: "DOMAIN & WEB HOSTING",
    services: [
      { name: "Domain Registration", link: "/domain-registration" },
      { name: "Web Hosting Services Linux/Windows", link: "/web-hosting" },
    ],
  },
];

const ServicesPage = () => {
  // State to store selected option for each service
  const [selectedOptions, setSelectedOptions] = useState({
    "Transactional SMS": "",
    "OTP SMS": "",
    "Voice SMS": "",
    "WhatsApp API": "",
  });

  // Handle the change in dropdown selection
  const handleSelectChange = (serviceName, event) => {
    setSelectedOptions({
      ...selectedOptions,
      [serviceName]: event.target.value,
    });
  };
  
  return (
    <div className="services-page">
      <h1>Our Services</h1>
      {services.map((serviceCategory, idx) => (
        <div key={idx} className={`service-category ${serviceCategory.category.toLowerCase().replace(" ", "-")}`}>
          <h2>{serviceCategory.category}</h2>
          <div className="service-list">
            {serviceCategory.services.map((service, index) => (
              <div key={index} className="service-item">
                <h3>{service.name}</h3>
                {service.options ? (
                  <>
                    <label htmlFor={`select-${service.name}`}>
                      Select No. of SMSs:
                    </label>
                    <select
                      id={`select-${service.name}`}
                      value={selectedOptions[service.name] || ""}
                      onChange={(e) => handleSelectChange(service.name, e)}
                    >
                      <option value="">Select</option>
                      {service.options.map((option, optionIndex) => (
                        <option key={optionIndex} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </>
                ) : null}
                <a href={service.link || "#"} className="book-now-btn">
                  Book Now
                </a>
              </div>
            ))}
          </div>
        </div>
      ))}
      <Footer />
    </div>
  );
};

export default ServicesPage;
