import React, { useState } from "react";
import Footer from "../components/Footer";
import './ContactPage.css';
import axios from "axios";


const Contact = () => {
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [generatedOtp, setGeneratedOtp] = useState(""); // State to store the generated OTP
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleMobileChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Only allow numbers
    setMobile(value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const sendOtp = async (mobileNumber) => {
    const username = "surajsolution";  
    const password_sms = "Pa$$2dattag9";  
    const template_id = '1207171437385909584'; 
    const sender = "SRVERM";  
    const route = 1;
    const peid = '1201159499271863189'; 
    const dltTemplateId = '1207171437385909584'; 

    const SMS_API_URL = "http://sms.surajenterprise.com/api/mt/SendSMS";

    // Validate mobile number (10 digits check)
    if (!mobile || mobile.length !== 10) {
      setErrorMessage("Invalid mobile number. It should be 10 digits.");
      return;
    }

    // Generate a random OTP (6 digits)
    const generatedOtp = Math.floor(1000 + Math.random() * 9000); 
    setGeneratedOtp(generatedOtp); // Store the generated OTP in state
    const message = `Dear User, Your OTP for login is ${generatedOtp}. Valid for 5 minutes. Please do not share this OTP to anyone. Regards, SurajEnterprise Team`;

    try {
      // Call the SMS Gateway API to send the OTP
      const response = await axios.get(SMS_API_URL, {
        params: {
          user: username,
          password: password_sms,
          senderid: sender,
          channel: 'Trans', // 'Trans' for Transactional SMS
          DCS: 0,
          flashsms: 0,
          number: mobile,
          text: message,
          route: route,
          peid: peid,
          DLTTemplateId: dltTemplateId
        }
      });

      if (response.data) {
        setIsOtpSent(true);
        setSuccessMessage('OTP sent successfully');
      } else {
        setErrorMessage('Failed to send OTP');
      }
    } catch (error) {
      setErrorMessage("Error sending OTP:", error);
    }
  };

  const verifyOtp = async () => {
    if (otp === "") {
      setErrorMessage("Please enter the OTP.");
      return;
    }

    // Compare the entered OTP with the generated OTP
    if (otp === generatedOtp.toString()) {
      setOtpVerified(true);
      setSuccessMessage("OTP verified successfully!");
      setErrorMessage(""); // Clear any error message
    } else {
      setErrorMessage("Invalid OTP. Please try again.");
    }
  };

  const [activeAccordion, setActiveAccordion] = useState(null);
  
  const toggleAccordion = (index) => {
    if (activeAccordion === index) {
      setActiveAccordion(null); // Close if already active
    } else {
      setActiveAccordion(index); // Open the clicked section
    }
  };

  return (
    <div className="mt-28">
      <div className="lg:px-20 px-8 bg-contact text-white h-[50vh] py-20 text-center flex justify-center items-center flex-col relative">
  <p className="lg:text-4xl text-2xl font-medium z-10 text-white">
    We'd love to hear from you
  </p>
  <p className="mt-2 z-10 text-white">
    Whether you have a question about features, trials, pricing, need a
    demo, or anything else, our team is ready to answer all your questions
  </p>
  <div className="overlay-con absolute top-0 left-0 w-full h-full"></div>
</div>


      {/* Branches Section (Accordion) */}
      <div className="contact-container">
        <div className="contact-left">
          <h1>Branches</h1>
          <div className="accordion">
            {/* Andhra Pradesh */}
            <div className="accordion-item">
              <button
                className="accordion-button"
                onClick={() => toggleAccordion(0)}
              >
                Andhra Pradesh
              </button>
              <div className={`accordion-content ${activeAccordion === 0 ? 'active' : ''}`}>
                <p>Opp: PostOffice, Gandhinagar, Vijayawada - 520003, Andhra Pradesh, INDIA. | +91 9494476996</p>
              </div>
            </div>
            {/* Telangana */}
            <div className="accordion-item">
              <button
                className="accordion-button"
                onClick={() => toggleAccordion(1)}
              >
                Telangana
              </button>
              <div className={`accordion-content ${activeAccordion === 1 ? 'active' : ''}`}>
                <p>Flat No: 6, Near Kalyan Nagar Phase - 1, S.R. Nagar, Ameerpet, Hyderabad, Telangana-500038. | +91 9912340901</p>
                <p>Flat No: 204, Near GHMC Play Park, Road No1, KPHB Colony, Hyderabad, Telangana - 500072. | +91 9912340901</p>
              </div>
            </div>
            {/* Gujarat */}
            <div className="accordion-item">
              <button
                className="accordion-button"
                onClick={() => toggleAccordion(2)}
              >
                Gujarat
              </button>
              <div className={`accordion-content ${activeAccordion === 2 ? 'active' : ''}`}>
                <p>22, Chatrapati Shivaji Marg Bungalow Society, Akshar Dham Society, Adajan, Surat, Gujarat 395009 | +91 6281668428</p>
              </div>
            </div>
          </div>
        </div>

        {/* Contact Form Section */}
        <div className="contact-right">
          <h1>Contact Us</h1>
          <form>
            <div className="bg-white grid px-4 py-4 banner-input w-full mt-10 lg:mt-0">
              <input type="text" placeholder="Your Name" />
              <input type="text" placeholder="Phone Number" value={mobile} onChange={handleMobileChange} maxLength="10" minLength="10" />

              {!isOtpSent && (
                <button type="button" className="otp-btn" onClick={() => sendOtp(mobile)}>
                  Send OTP
                </button>
              )}

              {isOtpSent && !otpVerified && (
                <div className="otp-section">
                  <input
                    required
                    type="text"
                    name="otp"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={handleOtpChange}
                  />
                  <button type="button" className="verify-otp-btn" onClick={verifyOtp}>
                    Verify OTP
                  </button>
                </div>
              )}

              {errorMessage && <p className="error-message">{errorMessage}</p>}
              {successMessage && <p className="success-message">{successMessage}</p>}

              <input type="text" placeholder="Email Address" />
              <textarea placeholder="Your Message" rows="2"></textarea>

              <div className="flex items-center mt-2">
  <input type="checkbox" id="agreeTerms" />
  <label htmlFor="agreeTerms" className="terms-checkbox">
    <span className="ml-2">I agree to,</span> 
    <a href="#" target="_blank" className="text-blue-500">
      I Authorize SurajEnterprise to send notifications via SMS/RCS/Call/Email/WhatsApp
    </a>
  </label>
</div>

              <button className="text-center mt-2 btn-gradient text-white px-6 py-2 w-[150px] rounded-full">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Google Maps Section */}
      <div className="map-section">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929563.8751475443!2d78.11857159210197!3d15.88470629531562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3546f8ae93d47f%3A0x33d1bbbe95adcd83!2sAndhra%20Pradesh!5e0!3m2!1sen!2sin!4v1741272076612!5m2!1sen!2sin"
          title="Map 1"
        ></iframe>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888600.950112694!2d76.63685376169961!3d17.86384441282973!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3350db9429ed43%3A0x63ef7ba741594059!2sTelangana!5e0!3m2!1sen!2sin!4v1741272183442!5m2!1sen!2sin"
          title="Map 2"
        ></iframe>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3777314.872995804!2d68.6848901800457!3d22.39949680100548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959051f5f0ef795%3A0x861bd887ed54522e!2sGujarat!5e0!3m2!1sen!2sin!4v1741272212702!5m2!1sen!2sin"
          title="Map 3"
        ></iframe>
      </div>

<br></br>
      <Footer />
    </div>
  );
};

export default Contact;
