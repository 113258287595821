import React, { useRef, useEffect, useState } from "react";
import { Link } from 'react-router-dom';


import About from "../components/About";
import Banner from "../components/Banner";
import Contact from "../components/Contact";
import Demo from "../components/Demo";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Header2 from "../components/Header2";
import Partner from "../components/Partner";
import Payment from "../components/Payment";
import Pricing from "../components/Pricing";
import Service from "../components/Service";
import ServicesPage from "../pages/ServicesPage";
import FAQPage from '../components/FAQPage';

const Home = () => {
  const [currentSection, setCurrentSection] = useState("home");

  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const serviceRef = useRef(null);
  const priceRef = useRef(null);
  const payRef = useRef(null);
  const partnerRef = useRef(null);
  const demoRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToSection = (section) => {
    if (section === "home") {
      homeRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "about") {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "service") {
      serviceRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "price") {
      priceRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "pay") {
      payRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "partner") {
      partnerRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "demo") {
      demoRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "contact") {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const homeOffset = homeRef.current.offsetTop;
      const aboutOffset = aboutRef.current.offsetTop;
      const serviceOffset = serviceRef.current.offsetTop;
      const priceOffset = priceRef.current.offsetTop;
      const payOffset = payRef.current.offsetTop;
      const partnerOffset = partnerRef.current.offsetTop;
      const demoOffset = demoRef.current.offsetTop;
      const contactOffset = contactRef.current.offsetTop;
      const currentPosition = window.scrollY + 10;

      console.log(aboutOffset);

      //console.log("about", aboutRef.current.offsetTop, currentPosition);
      //console.log("service", serviceRef.current.offsetTop, currentPosition);

      if (currentPosition >= homeOffset) {
        setCurrentSection("home");
      }
      if (currentPosition >= aboutOffset) {
        setCurrentSection("about");
      }
      
      if (currentPosition >= serviceOffset) {
        setCurrentSection("service");
      } 
      if (currentPosition >= priceOffset) {
        setCurrentSection("price");
      }
      if (currentPosition >= payOffset) {
        setCurrentSection("pay");
      }
      if (currentPosition >= partnerOffset) {
        setCurrentSection("partner");
      }
      if (currentPosition >= demoOffset) {
        setCurrentSection("demo");
      }
      if (currentPosition >= contactOffset) {
        setCurrentSection("contact");
      }
    };
    //window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentSection]);

  return (
    <div>
      {/* <Header current={currentSection} scrollToSection={scrollToSection} /> */}
      {/* <Header2 /> */}
      <Banner homeRef={homeRef} />
      <About aboutRef={aboutRef} />
      <Service serviceRef={serviceRef} />
      <Pricing priceRef={priceRef} />
      <Payment payRef={payRef} />
      <Partner partnerRef={partnerRef} />
      <Demo demoRef={demoRef} />
      <Contact contactRef={contactRef} />
      <Footer />


{/* Other Services Menu with links to ServicesPage and FAQPage */}
<nav>
        <ul>
          <li>
            <Link to="/ServicesPage">Services Page</Link>
          </li>
          <li>
            <Link to="/FAQPage">FAQ Page</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Home;
