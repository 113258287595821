import React from 'react';
import "./App.css";
import Home from "./pages/Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PromotionalSms from "./SMS/PromotionalSms";
import Transactional from "./SMS/Transactional";
import VoiceSms from "./SMS/VoiceSms";
import Payment from "./components/Payment";
import Header2 from "./components/Header2";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./pages/About";
import Policy from "./pages/Policy";
import Terms from "./pages/Terms";
import Disclimar from "./pages/Disclimar";
import Contact from "./pages/Contact";
import OtpSms from "./SMS/OtpSms";
import WebDesign from "./WEB/WebDesign";
import Website from "./WEB/Website";
import Logo from "./WEB/Logo";
import Mailer from "./WEB/Mailer";
import Email from "./EMIAL/Email";
import Email2 from "./EMIAL/Email2";
import IVR from "./OTHERS/IVR";
import RefundAndCancellation from "./pages/RefundAndCancellation";
import RCSMessaging from "./pages/RCSMessaging";
import ServicesPage from "./pages/ServicesPage";
import FAQPage from './components/FAQPage';




function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header2 />
        <Routes>
          <Route path="/Payments" exact element={<Payment />} />
          <Route path="/VoiceSms" exact element={<VoiceSms />} />
          <Route path="/transactional" exact element={<Transactional />} />
          <Route path="/promotionalSms" exact element={<PromotionalSms />} />
          <Route path="/otpSms" exact element={<OtpSms />} />
          <Route path="/" exact element={<Home />} />
          <Route path="/web-design" exact element={<WebDesign />} />
          <Route path="/website-development" exact element={<Website />} />
          <Route path="/logo-design" exact element={<Logo />} />
          <Route path="/mailer-design" exact element={<Mailer />} />
          <Route path="/promotional-email" exact element={<Email2 />} />
          <Route path="/premium-email" exact element={<Email />} />
          <Route path="/ivr-services" exact element={<IVR />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/privacy-policy" exact element={<Policy />} />
          <Route path="/terms" exact element={<Terms />} />
          <Route path="/disclimar" exact element={<Disclimar />} />
          <Route path="/RefundAndCancellation" exact element={<RefundAndCancellation />} />
          <Route path="/RCSMessaging" exact element={<RCSMessaging />} />
          <Route path="/ServicesPage" element={<ServicesPage />} />
          <Route path="/FAQPage" element={<FAQPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
