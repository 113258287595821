import React, { useRef, useState } from "react";
import Hero from "./Hero";
import Slider from "react-slick";
import "./Banner.css";
import axios from "axios";

const Banner = ({ homeRef }) => {
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [generatedOtp, setGeneratedOtp] = useState("");  // State to store the generated OTP
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleMobileChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");  // Only allow numbers
    setMobile(value);
  };
// testing 
  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const sendOtp = async (mobileNumber) => {
    const username = "surajsolution"; 
    const password_sms = "Pa$$2dattag9"; 
    const template_id = '1207171437385909584'; 
    const sender = "SRVERM"; 
    const route = 1;
    const peid = '1201159499271863189'; 
    const dltTemplateId = '1207171437385909584'; 

    const SMS_API_URL = "http://sms.surajenterprise.com/api/mt/SendSMS";

    // Validate mobile number (10 digits check)
    if (!mobile || mobile.length !== 10) {
      setErrorMessage("Invalid mobile number. It should be 10 digits.");
      return;
    }

    // Generate a random OTP (6 digits)
    const generatedOtp = Math.floor(1000 + Math.random() * 9000); 
    setGeneratedOtp(generatedOtp); // Store the generated OTP in state
    // const message = `Dear User, Your Identification number as a member is ${generatedOtp}. Thank you SurajEnterprise.`;
    const message = `Dear User, Your OTP for login is ${generatedOtp}. Valid for 5 minutes. Please do not share this OTP to anyone. Regards, SurajEnterprise Team`;


    
    

    try {
      // Call the SMS Gateway API to send the OTP
      const response = await axios.get(SMS_API_URL, {
        params: {
          user: username,
          password: password_sms,
          senderid: sender,
          channel: 'Trans', // 'Trans' for Transactional SMS, 'Prom' for Promotional
          DCS: 0,
          flashsms: 0,
          number: mobile,
          text: message,
          route: route,
          peid: peid,
          DLTTemplateId: dltTemplateId
        }
      });

      if (response.data) {
        setIsOtpSent(true);
        setSuccessMessage('OTP sent successfully');
      } else {
        setErrorMessage('Failed to send OTP');
      }
    } catch (error) {
      setErrorMessage("Error sending OTP:", error);
    }
  };

  // Function to verify the OTP entered by the user
  const verifyOtp = async () => {
    if (otp === "") {
      setErrorMessage("Please enter the OTP.");
      return;
    }

    // Compare the entered OTP with the generated OTP
    if (otp === generatedOtp.toString()) {
      setOtpVerified(true);
      setSuccessMessage("OTP verified successfully!");
      setErrorMessage(""); // Clear any error message
    } else {
      setErrorMessage("Invalid OTP. Please try again.");
    }
  };

  var settings = {
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    autoplaySpeed: 7000,
    pauseOnHover: false,
    arrows: true,
  };

  return (
    <>
      <section ref={homeRef}>
        <div className="relative">
          <form
            action="https://formsubmit.co/support@surajenterprise.com"
            method="POST"
            className="bg-white grid p-4 banner-input lg:mt-0 w-full absolute lg:right-20 right-0 lg:top-[180px] top-[500px] lg:w-2/6 z-10"
          >
            <input required type="text" name="name" placeholder="Your Name" />
            
            <div className="relative">
              <input
                required
                type="text"
                maxLength="10"
                minLength="10"
                name="mobile Number"
                placeholder="Phone Number"
                value={mobile}
                onChange={handleMobileChange}
              />
              {!isOtpSent && (
                <button
                  type="button"
                  className="otp-btn"
                  onClick={() => sendOtp(mobile)} // Pass mobile as argument
                >
                  Send OTP
                </button>
              )}
            </div>

            {isOtpSent && !otpVerified && (
              <div className="otp-section">
                <input
                  required
                  type="text"
                  name="otp"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={handleOtpChange}
                />
                <button
                  type="button"
                  className="verify-otp-btn"
                  onClick={verifyOtp}
                >
                  Verify OTP
                </button>
              </div>
            )}
            
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}

            <input required type="text" name="email" placeholder="Email Address" />
            <textarea name="message" placeholder="Your Message" rows="2"></textarea>
            <div className="flex items-center mt-2">
              <input type="checkbox" id="agreeTerms" />
              <label htmlFor="agreeTerms" className="ml-2 text-sm">
                I agree to the <a href="#" target="_blank" className="text-blue-500">I Authorize SurajEnterprise to send notifications via SMS/RCS/Call/Email/WhatsApp</a>
              </label>
            </div>
            <button className="text-center mt-2 btn-gradient text-white px-6 py-2 w-[150px] rounded-full">
              Submit
            </button>
          </form>

          <Slider {...settings} className="test">
            <div className=" lg:px-20 px-5 py-28 relative b b1 ">
              <div className="lg:grid lg:grid-cols-2 flex flex-col items-center">
                <div className="px-4 h-[400px] items-center w-full grid z-10">
                  <div>
                    <h1 className="text-white font-medium text-4xl lg:text-6xl">
                      Digital marketing
                    </h1>
                    <p className="text-white mt-4">
                      For our best Promotional Email service, which will help
                      you receive more high-value prospects, get in touch with
                      Suraj Enterprise.
                    </p>
                  </div>
                </div>
                {/* <div className="bg-white grid p-8 banner-input mt-10 lg:mt-0 w-full">
                  <input required type="text" placeholder="Your Name" />
                  <input required type="text" placeholder="Phone Number" />
                  <input type="text" placeholder="Email Address" />
                  <textarea placeholder="Your Message" rows="2"></textarea>
                  <input type="text" placeholder="Enter Captcha" />
                  <button className="text-start btn-gradient text-white px-6 py-2 w-[150px] rounded-full">
                    Send Message
                  </button>
                </div> */}
              </div>
              <div
                className="absolute w-full h-full top-0 left-0"
                style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
              ></div>
            </div>
            <div className="b b2 relative lg:px-20 px-5 py-28">
              <div className="lg:grid lg:grid-cols-2 flex flex-col items-center">
                <div className="px-4 h-[400px] items-center w-full grid z-10">
                  <div>
                    <h1 className="text-white font-medium text-4xl lg:text-6xl">
                      Web Design
                    </h1>
                    <p className="text-white mt-4">
                      Give your website a vibrant, highly engaging look with
                      flawless design that incorporates all the features and
                      functionalities needed for it to function at its best.
                      {/* Suraj Enterprise offers top-notch website designs with
                      features that will draw customers in addition to
                      persuading them to purchase your goods and services. */}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="absolute w-full h-full top-0 left-0"
                style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
              ></div>
            </div>
            <div className="b b3 relative lg:px-20 px-5 py-28">
              <div className="lg:grid lg:grid-cols-2 flex flex-col items-center">
                <div className="px-4 h-[400px] items-center w-full grid z-10">
                  <div>
                    <h1 className="text-white font-medium text-4xl lg:text-6xl">
                      Bulk SMS
                    </h1>
                    {/* <p className="text-white mt-4">
                      Suraj Enterprise, one of the leading B2B and B2C SMS
                      providers in India, has developed the best promotional SMS
                      plans that are pocket-friendly and customizable to your
                      business needs.
                    </p> */}
                  </div>
                </div>
              </div>
              <div
                className="absolute w-full h-full top-0 left-0"
                style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
              ></div>
            </div>
            <div className="b b4 relative lg:px-20 px-5 py-28">
              <div className="lg:grid lg:grid-cols-2 flex flex-col items-center">
                <div className="px-4 h-[400px] items-center w-full grid z-10">
                  <div>
                    <h1 className="text-white font-medium text-4xl lg:text-6xl">
                      Voice SMS
                    </h1>
                    <p className="text-white mt-4">
                      Get credit for missed calls with SurajEnterprise. Suraj
                      Enterprise is one of the leading B2B and B2C SMS service
                      providers in India.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="absolute w-full h-full top-0 left-0"
                style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
              ></div>
            </div>
            <div className="b b5 relative lg:px-20 px-5 py-28">
              <div className="lg:grid lg:grid-cols-2 flex flex-col items-center">
                <div className="px-4 h-[400px] items-center w-full grid z-10">
                  <div>
                    {/* <h1 className="text-white font-medium text-4xl lg:text-6xl">
                      Other Services
                    </h1>
                    <p className="text-white mt-4">
                      The importance of keeping in touch with customers has
                      risen as a result of greater awareness. Every firm strives
                      to keep a relationship with customers in order to thrive.
                    </p> */}
                  </div>
                </div>
              </div>
              <div
                className="absolute w-full h-full top-0 left-0"
                style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
              ></div>
            </div>
          </Slider>
        </div>
        {/* <div className="bg-gradient  lg:px-20 px-5 py-28">
          <div className="lg:grid lg:grid-cols-2 flex flex-col items-center">
            <div className="px-4">
              <h1 className="text-white font-medium text-4xl lg:text-6xl">
                SMS Root - Best Bulk SMS company in India!
              </h1>
              <p className="text-white mt-4">
                We Offer Cheapest Bulk SMS Services with High Priority SMS
                Gateway. We offer 02 Lac Promotional SMS @ Rs 12000 only & 02
                Lac Transactional SMS @ Rs 16000 only. BUY Bulksms NOW !!!
              </p>
            </div>
            <div className="bg-white grid p-8 banner-input mt-10 lg:mt-0 w-full">
              <input type="text" placeholder="Your Name" />
              <input type="text" placeholder="Phone Number" />
              <input type="text" placeholder="Email Address" />
              <textarea placeholder="Your Message" rows="2"></textarea>
              <input type="text" placeholder="Enter Captcha" />
              <button className="text-start btn-gradient text-white px-6 py-2 w-[150px] rounded-full">
                Send Message
              </button>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default Banner;