import React, { useRef } from "react";
import p1 from "../assets/p1.jpg";
import p2 from "../assets/p2.jpg";
import p3 from "../assets/p3.jpg";
import Footer from "./Footer";
import raz from "../assets/razorpay.png";

const Payment = ({ payRef }) => {
  return (
    <>
      <section ref={payRef}>
        <div className="text-center mt-10 lg:px-20 px-10 py-10 md:py-20">
          <h1 className="text-gray text-4xl">Suraj Enterprise Payment</h1>

          <h4 className="text-gray2 m-4">
            You Can Make Bulk SMS Payment By Cash/Net Banking/Check at:
          </h4>

          <div className="lg:grid lg:grid-cols-2 flex flex-col">
            {/* <div className="mt-4 px-4">
            <h1 className="text-gray2 font-medium text-lg mt-2">
              PAY FROM DEBIT CARD / CREDIT CARD:
            </h1>
            <button className="bg-[#75c26a] text-white w-full py-2 rounded mt-4">
              Pay
            </button>
          </div> */}
            {/* <h2 className="font-medium text-lg mt-2"> </h2> */}
            <a
              href="https://pages.razorpay.com/sepayment"
              className="flex px-2 border rounded-md items-center justify-center flex-wrap hover:bg-skyblue  transition-all duration-300 hover:scale-95"
            >
              <img src={raz} alt="" className="w-28 h-14" />
              <span className="  p-3 ">
                Click here to pay throught Razorpay
              </span>
            </a>

            {/* <h2 className="text-gray2 font-medium text-lg mt-2">
              Bank Name : IDFC First Bank
            </h2>
            <h2 className="text-gray2 font-medium text-lg mt-2">
              A/C Name : Labz it services
            </h2>
            <h2 className="text-gray2 font-medium text-lg mt-2">
              A/C No. : 10038475819
            </h2>
            <h2 className="text-gray2 font-medium text-lg mt-2">
              IFS Code : IDFB0080151
            </h2> */}
            <div className="px-4 border rounded-md ml-4">
              <h2 className="text-gray2 font-medium text-lg mt-2">
               <b>PHONE PE / GOOGLE PAY / PAYTM:</b>
              </h2>
              <h2 className="text-gray2 font-medium text-lg mt-2">
                9494476996 (G V Sai Krishna)
              </h2>
            </div>
          </div>

          <div className="lg:grid lg:grid-cols-3 flex flex-col py-10 md:py-20 gap-8">
            <img src={p1} alt="" />
            <img src={p2} alt="" />
            <img src={p3} alt="" />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Payment;
