import React, { useState } from 'react';
import './FAQPage.css';
import './Banner.css';

const FAQPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null); // Close the currently open question if clicked again
    } else {
      setActiveIndex(index); // Open the clicked question
    }
  };

  const faqs = [
    {
      question: "What is Bulk SMS and what is its basic use?",
      answer: "Bulk SMS is a platform where you can simply send a million texts at the same time, with a simple click of a button. It is used to make any sort of news, information, product, services, and brand to various people."
    },
    {
      question: "Why is it essential for businesses to use bulk SMS?",
      answer: "SMS beats every other channel hands down and offers ten times the response rate, in today’s world of cell phone. Bulk SMS can help enhance your conversion rates across the customer lifecycle - acquisition, engagement, and retention by implementing action links within the message."
    },
    {
      question: "Can I have a free trial before buying the bulk SMS services?",
      answer: "Of course you can. We offer such services to our clients. Before you buy our services, we give you details of our dummy account. You can use them as per your needs and requirements."
    },
    {
      question: "Will I get a confirmation text once my messages are delivered?",
      answer: "It is quite easy. You will get a confirmation text for your messages in your account itself."
    },
    {
      question: "How many SMS’s can I send in a day?",
      answer: "You can easily send up to 10 Lakh texts in a day."
    },
    {
      question: "What is Sender ID?",
      answer: "Sender ID is often seen as the name in the header of the SMS. The name could be alphanumeric with maximum characters or the name of the company."
    },
    {
      question: "What kind of SMS’s can I send on DND numbers?",
      answer: "Transactional and Promotional SMS can’t be sent on DND numbers under any circumstances."
    },
    {
      question: "How can I schedule texts for the future?",
      answer: "You simply have to go to the message box, bulk SMS, and tick on advanced settings. You can either schedule in bulk or in single."
    }
  ];

  return (
    <div>
      <Banner /> {/* Render the Banner component here */}
      <div className="faq-container">
        <h1 className="faq-title">FAQ's</h1>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div key={index} className={`faq-item ${activeIndex === index ? 'active' : ''}`}>
              <div className="faq-question" onClick={() => handleToggle(index)}>
                <h3>{faq.question}</h3>
                <span className={`toggle-icon ${activeIndex === index ? 'rotate' : ''}`}>&#x2193;</span>
              </div>
              {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Banner = () => {
  return (
    <div className="banner-container">
      <div className="banner-content">
        <h1 className="banner-title">Frequently Asked Questions      </h1>
        {/* <p className="banner-subtitle">Your gateway to the best services</p> 
        <button className="banner-button">
  <a href="/contact" target="_blank" rel="noopener noreferrer">Get Started</a>
</button>  */}

      </div>
    </div>
  );
};

export default FAQPage;
