import React, { useState } from 'react';
import './RCSMessaging.css'; 
import { Link } from 'react-router-dom'; // Import Link for sub-page navigation
import rcsImage from '../assets/images/rcs_SE.png';  
import enhancedUserExperienceImage from '../assets/images/enhanced-user-experience.jpg'; 
import betterConversionsImage from '../assets/images/better-conversions.jpg'; 
import secureMessagingImage from '../assets/images/secure-messaging.jpg'; 
import customer1Image from '../assets/images/customer1.jpg';  
import customer2Image from '../assets/images/customer2.png';  
import customer3Image from '../assets/images/customer3.png';  
import customer4Image from '../assets/images/customer4.jpg';
import customer5Image from '../assets/images/customer5.jpg';
import customer6Image from '../assets/images/customer6.jpg';
import customer7Image from '../assets/images/customer7.png';
import customer8Image from '../assets/images/customer8.jpg';
import customer9Image from '../assets/images/customer9.png';
import customer10Image from '../assets/images/customer10.png';
import customer11Image from '../assets/images/customer11.jpg';
import customer12Image from '../assets/images/customer12.jpg';
import customer13Image from '../assets/images/customer13.png';
import customer14Image from '../assets/images/customer14.jpg';
import customer15Image from '../assets/images/customer15.png';
import customer16Image from '../assets/images/customer16.jpg';
import customer17Image from '../assets/images/customer17.jpg';
import customer18Image from '../assets/images/customer18.png';
import Footer from '../components/Footer';

// RCS Messaging Component
const RCSMessaging = () => {
    const [activeAccordion, setActiveAccordion] = useState(null);

    // Toggle the accordion visibility
    const toggleAccordion = (index) => {
        setActiveAccordion(activeAccordion === index ? null : index);
    };

    return (
        <div className="rcs-container">
            {/* Header Section */}
            <header className="rcs-header">
                <h1>RCS Messaging</h1>
                <b><p className="main-heading">Next-generation communication for modern businesses</p></b> 
            </header>

            {/* What is RCS Messaging? Section */}
            <section className="rcs-content">
                <div className="rcs-info">
                    <h2 className="side-heading">What is RCS Messaging?</h2> 
                    <p className="info-paragraph">
                        Rich Communication Services (RCS) is the latest evolution in messaging, enabling brands and businesses to deliver engaging, interactive, and feature-rich messages directly to customers. With RCS, you can provide dynamic content, personalized experiences, and real-time updates seamlessly.
                    </p>
                </div>

                <div className="rcs-image">
                    <img 
                        src={rcsImage} 
                        alt="RCS Messaging" 
                        className="rcs-image-style"
                    />
                </div>
            </section>

            {/* Key Features Section */}
            <section className="rcs-features">
                <h2 className="features-heading">Key Features</h2>
                <div className="features-list">
                    <div className="feature-item">
                        <h3>Rich Media Support</h3>
                        <p>Support images, videos, and carousels to enrich communication.</p>
                    </div>
                    <div className="feature-item">
                        <h3>Real-Time Interactions</h3>
                        <p>Enable instant communication and updates with users.</p>
                    </div>
                    <div className="feature-item">
                        <h3>Verified Sender Identities</h3>
                        <p>Ensure your messages come from a verified source for authenticity.</p>
                    </div>
                    <div className="feature-item">
                        <h3>Interactive Call-to-Action</h3>
                        <p>Incorporate interactive elements like buttons for better engagement.</p>
                    </div>
                    <div className="feature-item">
                        <h3>Enhanced Analytics</h3>
                        <p>Track and analyze user interactions for better marketing strategies.</p>
                    </div>
                </div>
            </section>

            {/* Why Choose RCS Section */}
            <section className="rcs-benefits">
                <h2 className="benefits-heading">Why Choose RCS?</h2>
                <div className="benefits-grid">
                    <div className="benefit-item">
                        <img src={enhancedUserExperienceImage} alt="Enhanced User Experience" className="benefit-image" />
                        <h3>Enhanced User Experience</h3>
                        <p>Engage customers with visually appealing and interactive content.</p>
                        <Link to="/enhanced-user-experience" className="sub-page-link">Learn More</Link>
                    </div>

                    <div className="benefit-item">
                        <img src={betterConversionsImage} alt="Better Conversions" className="benefit-image" />
                        <h3>Better Conversions</h3>
                        <p>Drive more conversions with interactive call-to-action buttons.</p>
                        <Link to="/better-conversions" className="sub-page-link">Learn More</Link>
                    </div>

                    <div className="benefit-item">
                        <img src={secureMessagingImage} alt="Secure Messaging" className="benefit-image" />
                        <h3>Secure Messaging</h3>
                        <p>Ensure secure and verified communication with your customers.</p>
                        <Link to="/secure-messaging" className="sub-page-link">Learn More</Link>
                    </div>
                </div>
            </section>

            {/* Trusted Customers Section */}
            <section className="trusted-customers">
                <h2>Trusted by Leading Brands</h2>
                <p>Boost your business with Suraj Enterprise, trusted by over 50 companies for effective RCS, WhatsApp, and SMS communication solutions.</p>

                <div className="customers-grid">
                    <div className="customer-item"><img src={customer1Image} alt="Customer 1" /></div>
                    <div className="customer-item"><img src={customer2Image} alt="Customer 2" /></div>
                    <div className="customer-item"><img src={customer3Image} alt="Customer 3" /></div>
                    <div className="customer-item"><img src={customer4Image} alt="Customer 4" /></div>
                    <div className="customer-item"><img src={customer5Image} alt="Customer 5" /></div>
                    <div className="customer-item"><img src={customer6Image} alt="Customer 6" /></div>
                    <div className="customer-item"><img src={customer7Image} alt="Customer 7" /></div>
                    <div className="customer-item"><img src={customer8Image} alt="Customer 8" /></div>
                    <div className="customer-item"><img src={customer9Image} alt="Customer 9" /></div>
                    <div className="customer-item"><img src={customer10Image} alt="Customer 10" /></div>
                    <div className="customer-item"><img src={customer11Image} alt="Customer 11" /></div>
                    <div className="customer-item"><img src={customer12Image} alt="Customer 12" /></div>
                    <div className="customer-item"><img src={customer13Image} alt="Customer 13" /></div>
                    <div className="customer-item"><img src={customer14Image} alt="Customer 14" /></div>
                    <div className="customer-item"><img src={customer15Image} alt="Customer 15" /></div>
                    <div className="customer-item"><img src={customer16Image} alt="Customer 16" /></div>
                    <div className="customer-item"><img src={customer17Image} alt="Customer 17" /></div>
                    <div className="customer-item"><img src={customer18Image} alt="Customer 18" /></div>
                </div>
            </section>

            {/* Accordion Section */}
            <section className="rcs-accordion">
                <h2>A cutting-edge communication protocol that offers a wealth of rich experience for your professional life</h2>
                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(0)}>
                        Google Verification Badge
                    </button>
                    <div className={`accordion-content ${activeAccordion === 0 ? 'show' : ''}`}>
                        <p>Details about the Google Verification Badge...</p>
                    </div>
                </div>

                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(1)}>
                        Trusted Branding
                    </button>
                    <div className={`accordion-content ${activeAccordion === 1 ? 'show' : ''}`}>
                        <p>Details about Trusted Branding...</p>
                    </div>
                </div>

                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(2)}>
                        Rich Media Cards
                    </button>
                    <div className={`accordion-content ${activeAccordion === 2 ? 'show' : ''}`}>
                        <p>Details about Rich Media Cards...</p>
                    </div>
                </div>

                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(3)}>
                        Carousels
                    </button>
                    <div className={`accordion-content ${activeAccordion === 3 ? 'show' : ''}`}>
                        <p>Details about Carousels...</p>
                    </div>
                </div>

                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(4)}>
                        Call To Action Buttons
                    </button>
                    <div className={`accordion-content ${activeAccordion === 4 ? 'show' : ''}`}>
                        <p>Details about Call To Action Buttons...</p>
                    </div>
                </div>

                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(5)}>
                        Instant Reply’s
                    </button>
                    <div className={`accordion-content ${activeAccordion === 5 ? 'show' : ''}`}>
                        <p>Details about Instant Replies...</p>
                    </div>
                </div>

                <div className="accordion-item">
                    <button className="accordion-header" onClick={() => toggleAccordion(6)}>
                        Analytics
                    </button>
                    <div className={`accordion-content ${activeAccordion === 6 ? 'show' : ''}`}>
                        <p>Details about Analytics...</p>
                    </div>
                </div>
            </section>

            {/* Pricing Table Section */}
            <RCSPricingTable />
            <Footer />
                    </div>
    );
};

// RCS Pricing Table Component
const RCSPricingTable = () => {
    return (
        <div className="pricing-container">
            <h2 className="pricing-heading">RCS Messaging Pricing</h2>
            <table className="pricing-table">
                <thead>
                    <tr>
                        <th>Message Type</th>
                        <th>Domestic</th>
                        <th>International</th>
                        <th>Outgoing</th>
                        <th>Incoming</th>
                        <th>Buy</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Incoming Messages Customer Conversation</td>
                        <td>INR 0.15</td>
                        <td>Based on country operator</td>
                        <td>--</td>
                        <td>--</td>
                        <td><Link to="/Payments" className="sub-page-link">Buy</Link></td>
                    </tr>
                    <tr>
                        <td>Rich Card Messages Images, Videos & Call to actions</td>
                        <td>INR 0.26</td>
                        <td>Based on country operator</td>
                        <td>--</td>
                        <td>--</td>
                        <td><Link to="/Payments" className="sub-page-link">Buy</Link></td>
                    </tr>
                    <tr>
                        <td>Simple Message OTP, Notification etc</td>
                        <td>INR 0.18</td>
                        <td>Based on country operator</td>
                        <td>--</td>
                        <td>--</td>
                        <td><Link to="/Payments" className="sub-page-link">Buy</Link></td>
                    </tr>
                </tbody>
            </table>



        </div>
    );
};



export default RCSMessaging;

