export const plan1 = [
  {
    id: 1,
    plan: "Silver Standard Bulk SMS Plan",
    price: "1700",
    p1: "10,000 SMS - 17paisa per SMS",
    p2: "Delivers 24 x 7",
    p3: "Delivery to Dnd Numbers also",
    p4: "18% GST Extra",
    p5: "No Api",
    p6: "06 Alphabets Sender Id",
    p7: "Lifetime Validity*",
    p8: "100% Advance Payment",
  },
  {
    id: 2,
    plan: "Gold Standard Bulk SMS Plan",
    price: "6000",
    p1: "50,000 SMS - 12paisa per SMS",
    p2: "Delivers 24 x 7",
    p3: "Delivery to Dnd Numbers also",
    p4: "18% GST Extra",
    p5: "No Api",
    p6: "06 Alphabets Sender Id",
    p7: "Lifetime Validity*",
    p8: "100% Advance Payment",
  },
  {
    id: 3,
    plan: "Platinum Standard Bulk SMS Plan",
    price: "10000",
    p1: "1,00,000 SMS - 10paisa per SMS",
    p2: "Delivers 24 x 7",
    p3: "Delivery to Dnd Numbers also",
    p4: "18% GST Extra",
    p5: "No Api",
    p6: "06 Alphabets Sender Id",
    p7: "Lifetime Validity*",
    p8: "100% Advance Payment",
  },
  {
    id: 4,
    plan: "Super Saver Standard Bulk SMS Plan",
    price: "16000",
    p1: "2,00,000 SMS - 08paisa per SMS",
    p2: "Delivers 24 x 7",
    p3: "Delivery to Dnd Numbers also",
    p4: "18% GST Extra",
    p5: "No Api",
    p6: "06 Alphabets Sender Id",
    p7: "Lifetime Validity*",
    p8: "100% Advance Payment",
  },
];

export const plan2 = [
  {
    id: 1,
    plan: "Silver Bulk SMS Plan",
    price: "2000",
    p1: "10,000 SMS - 20paisa per SMS",
    p2: "Delivers 24 x 7",
    p3: "Delivery to Dnd Numbers also",
    p4: "18% GST Extra",
    p5: "Free API",
    p6: "06 Alphabets Sender Id",
    p7: "Lifetime Validity*",
    p8: "100% Advance Payment",
  },
  {
    id: 2,
    plan: "Gold Bulk SMS Plan",
    price: "7500",
    p1: "50,000 SMS - 15paisa per SMS",
    p2: "Delivers 24 x 7",
    p3: "Delivery to Dnd Numbers also",
    p4: "18% GST Extra",
    p5: "Free API",
    p6: "06 Alphabets Sender Id",
    p7: "Lifetime Validity*",
    p8: "100% Advance Payment",
  },
  {
    id: 3,
    plan: "",
    price: "13000",
    p1: "1,00,000 SMS - 13 paisa per SMS",
    p2: "Delivers 24 x 7",
    p3: "Delivery to Dnd Numbers also",
    p4: "18% GST Extra",
    p5: "Free API",
    p6: "06 Alphabets Sender Id",
    p7: "Lifetime Validity*",
    p8: "100% Advance Payment",
  },
  {
    id: 4,
    plan: "Super Saver SMS Plan",
    price: "60000",
    p1: "5,00,000 SMS - 12 paisa per SMS",
    p2: "Delivers 24 x 7",
    p3: "Delivery to Dnd Numbers also",
    p4: "18% GST Extra",
    p5: "Free API",
    p6: "06 Alphabets Sender Id",
    p7: "Lifetime Validity*",
    p8: "100% Advance Payment",
  },
];

export const plan3 = [
  {
    id: 1,
    plan: "Silver SMS Plan",
    price: "1500",
    p1: "10,000 SMS - 15paisa per SMS",
    p2: "Delivery between 10am To 9pm",
    p3: "No Delivery to Dnd Numbers",
    p4: "18% GST Extra",
    p5: "No Api",
    p6: "10 Digit Sender Id",
    p7: "Lifetime Validity*",
    p8: "100% Advance Payment",
  },
  {
    id: 2,
    plan: "Gold Bulk SMS Plan",
    price: "5000",
    p1: "50,000 SMS - 10 paisa per SMS",
    p2: "Delivery between 9am To 9pm",
    p3: "No Delivery to Dnd Numbers",
    p4: "18% GST Extra",
    p5: "No Api",
    p6: "06 Alphabets Sender Id",
    p7: "Lifetime Validity*",
    p8: "100% Advance Payment",
  },
  {
    id: 3,
    plan: "Platinum Promo SMS Plan",
    price: "9000",
    p1: "1,00,000 SMS - 08 paisa per SMS",
    p2: "Delivery between 9am To 9pm",
    p3: "No Delivery to Dnd Numbers",
    p4: "18% GST Extra",
    p5: "No Api",
    p6: "06 Alphabets Sender Id",
    p7: "Lifetime Validity*",
    p8: "100% Advance Payment",
  },
  {
    id: 4,
    plan: "Super Saver Bulk SMS Plan",
    price: "16000",
    p1: "2,00,000 SMS - 08 paisa per SMS",
    p2: "Delivery between 9am To 9pm",
    p3: "No Delivery to Dnd Numbers",
    p4: "18% GST Extra",
    p5: "No Api",
    p6: "06 Alphabets Sender Id",
    p7: "Lifetime Validity*",
    p8: "100% Advance Payment",
  },
];

export const plan4 = [
  {
    id: 1,
    price: "2000",
    p1: "5,000 SMS - 30paisa per SMS",
    p2: "Deliver Monday - Saturday Between 9 Am - 6 Pm",
    p3: "18% GST Extra",
    p4: "100% Advance Payment",
  },
  {
    id: 2,
    price: "2500",
    p1: "10,000 SMS - 25paisa per SMS",
    p2: "Deliver Monday - Saturday Between 9 Am - 6 Pm",
    p3: "18% GST Extra",
    p4: "100% Advance Payment",
  },
  {
    id: 3,
    price: "5000",
    p1: "25,000 SMS - 24paisa per SMS",
    p2: "Deliver Monday - Saturday Between 9 Am - 6 Pm",
    p3: "18% GST Extra",
    p4: "100% Advance Payment",
  },
  {
    id: 4,
    price: "7500",
    p1: "50,000 SMS - 15paisa per SMS",
    p2: "Deliver Monday - Saturday Between 9 Am - 6 Pm",
    p3: "18% GST Extra",
    p4: "100% Advance Payment",
  },
];

export const plan5 = [
  {
    id: 1,
    price: "12000",
    p1: "1 Lac SMS - 12paisa per SMS",
    p2: "Deliver Monday - Saturday Between 9 Am - 6 Pm",
    p3: "18% GST Extra",
    p4: "100% Advance Payment",
  },
  {
    id: 2,
    price: "22000",
    p1: "2 Lac SMS - 11paisa per SMS",
    p2: "Deliver Monday - Saturday Between 9 Am - 6 Pm",
    p3: "18% GST Extra",
    p4: "100% Advance Payment",
  },
  {
    id: 3,
    price: "50000",
    p1: "5 Lac SMS - 10paisa per SMS",
    p2: "Deliver Monday - Saturday Between 9 Am - 6 Pm",
    p3: "18% GST Extra",
    p4: "100% Advance Payment",
  },
  {
    id: 4,
    price: "90000",
    p1: "10 Lac SMS - 09paisa per SMS",
    p2: "Deliver Monday - Saturday Between 9 Am - 6 Pm",
    p3: "18% GST Extra",
    p4: "100% Advance Payment",
  },
];

export const plan6 = [
  {
    id: 1,
    price: "1000",
    p1: "2,000 SMS - 50paisa per SMS",
    p2: "Delivers 8 Am - 9 Pm",
    p3: "No API Available",
    p4: "18% GST Extra",
    p5: "100% Advance Payment",
  },
  {
    id: 2,
    price: "2000",
    p1: "5,000 SMS - 40paisa per SMS",
    p2: "Delivers 8 Am - 9 Pm",
    p3: "No API Available",
    p4: "18% GST Extra",
    p5: "100% Advance Payment",
  },
  {
    id: 3,
    price: "3000",
    p1: "10,000 SMS - 30paisa per SMS",
    p2: "Delivers 8 Am - 9 Pm",
    p3: "No API Available",
    p4: "18% GST Extra",
    p5: "100% Advance Payment",
  },
  {
    id: 4,
    price: "8000",
    p1: "25,000 SMS - 32paisa per SMS",
    p2: "Delivers 8 Am - 9 Pm",
    p3: "No API Available",
    p4: "18% GST Extra",
    p5: "100% Advance Payment",
  },
];

export const plan7 = [
  {
    id: 1,
    price: "13000",
    p1: "50,000 SMS - 26paisa per SMS",
    p2: "Delivers 8 Am - 9 Pm",
    p3: "No API Available",
    p4: "18% GST Extra",
    p5: "100% Advance Payment",
  },
  {
    id: 2,
    price: "22000",
    p1: "1 Lac SMS - 22paisa per SMS",
    p2: "Delivers 8 Am - 9 Pm",
    p3: "No API Available",
    p4: "18% GST Extra",
    p5: "100% Advance Payment",
    new: true,
  },
  {
    id: 3,
    price: "38000",
    p1: "2 Lac SMS - 19paisa per SMS",
    p2: "Delivers 8 Am - 9 Pm",
    p3: "No API Available",
    p4: "18% GST Extra",
    p5: "100% Advance Payment",
  },
];

export const plan8 = [
  {
    id: 1,
    price: "1700",
    p1: "10,000 SMS - 17paisa per SMS",
    p2: "Delivers 9 Am - 9 Pm",
    p3: "No Delivery On DND Numbers",
    p4: "No API Available",
    p5: "18% GST Extra",
    p6: "100% Advance Payment",
  },
  {
    id: 2,
    price: "4000",
    p1: "25,000 SMS - 16paisa per SMS",
    p2: "Delivers 9 Am - 9 Pm",
    p3: "No Delivery On DND Numbers",
    p4: "No API Available",
    p5: "18% GST Extra",
    p6: "100% Advance Payment",
  },
  {
    id: 3,
    price: "6000",
    p1: "50,000 SMS - 12paisa per SMS",
    p2: "Delivers 9 Am - 9 Pm",
    p3: "No Delivery On DND Numbers",
    p4: "No API Available",
    p5: "18% GST Extra",
    p6: "100% Advance Payment",
  },
  {
    id: 4,
    price: "9000",
    p1: "1 Lac SMS - 9paisa per SMS",
    p2: "Delivers 9 Am - 9 Pm",
    p3: "No Delivery On DND Numbers",
    p4: "No API Available",
    p5: "18% GST Extra",
    p6: "100% Advance Payment",
    new: true,
  },
];

export const plan9 = [
  {
    id: 1,
    price: "16000",
    p1: "2 Lac SMS - 08paisa per SMS",
    p2: "Delivers 9 Am - 9 Pm",
    p3: "No Delivery On DND Numbers",
    p4: "No API Available",
    p5: "18% GST Extra",
    p6: "100% Advance Payment",
  },
  {
    id: 2,
    price: "35000",
    p1: "5 Lac SMS - 07paisa per SMS",
    p2: "Delivers 9 Am - 9 Pm",
    p3: "No Delivery On DND Numbers",
    p4: "No API Available",
    p5: "18% GST Extra",
    p6: "100% Advance Payment",
  },
  {
    id: 3,
    price: "60000",
    p1: "10 Lac SMS - 06paisa per SMS",
    p2: "Delivers 9 Am - 9 Pm",
    p3: "No Delivery On DND Numbers",
    p4: "No API Available",
    p5: "18% GST Extra",
    p6: "100% Advance Payment",
  },
];
