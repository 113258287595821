import React, { useRef } from "react";
import s1 from "../assets/s1.png";
import s2 from "../assets/s2.png";

const Service = ({ serviceRef }) => {
  return (
    <section ref={serviceRef}>
      <div className=" py-10 md:py-20 flex flex-col items-center">
        <div className="lg:grid lg:grid-cols-3">
          <div>
            <img src={s1} alt="" />
          </div>
          <div className="lg:px-20 px-10 col-span-2 mt-10 lg:mt-0">
            <h2 className="text-gray2">ABOUT OUR SERVICES</h2>
            <h1 className="text-5xl text-gray service-line mt-2">
              Bulk Sms Services
            </h1>
            <p className="text-gray2">
              Our Reliable and quality bulk sms services would help you manage
              your business efficiently.
            </p>

            <div className="lg:grid lg:grid-cols-2 flex flex-col">
              <Card
                title="Missed Call Alert"
                desc="We offer a quick and effive Missed Call Alert services..."
              />
              <Card
                title="Transactional Bulksms"
                desc="Our cheap transactional bulk sms services would allows you to communicate with your clients easily..."
              />
              <Card
                title="Promotional Bulksms"
                desc="We offer the cheapest Promotional bulk sms service for promoting your business using online sms marketing in India..."
              />
              <Card
                title="Voice Bulk SMS"
                desc="Voice sms are also one of the effective online sms marketing service offered by us..."
              />
            </div>
          </div>
        </div>

        {/* SERVICES 2 */}
        <div className="lg:grid lg:grid-cols-3 mt-6 md:mt-20">
          <div className="lg:px-20 px-10 col-span-2 mt-10 lg:mt-0">
            <h2 className="text-gray2">ABOUT BULK SMS SERVICES</h2>
            <h1 className="text-5xl text-gray service-line mt-2">
              Other Services
            </h1>
            {/* <p className="text-gray2">
            Our Reliable and quality bulk sms services would help you manage
            your business efficiently.
          </p> */}

            <div className="lg:grid lg:grid-cols-2 flex flex-col">
              <Card
                title="Ecommerce Website Development"
                desc="We also offer ecommerce website development..."
              />
              <Card
                title="Website Design"
                desc="We offer a great website designs for your business..."
              />
              <Card
                title="Digital Marketing"
                desc="We have a strong team for digital marketing services along with bulk sms marketing services..."
              />
              <Card
                title="Website Hosting"
                desc="We offer a fast, effective and reliable web hosting services..."
              />
            </div>
          </div>
          <div>
            <img src={s2} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;

function Card({ title, desc }) {
  return (
    <div className="mt-8 text-gray   ">
      <h1 className="font-medium text-lg ">{title}</h1>
      <p className="text-gray2 mt-2">{desc}</p>
    </div>
  );
}
