import React, { useRef } from "react";

const Demo = ({ demoRef }) => {
  return (
    <section ref={demoRef}>
      <div className="pt-20">
        <div className="text-center">
          <h1 className="about-line text-gray2">DEMO PANEL LOGIN DETAILS</h1>
          <h4 className="text-blue">http://sms.surajenterprise.com/Account/Login</h4>
          <p className="text-gray text-lg font-medium mt-3">
            Click 'Register New' & Fill the form to get your Login details on
            your mobile / Email.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Demo;
