import React, { useRef } from "react";

const Contact = ({ contactRef }) => {
  return (
    <section ref={contactRef}>
      <div className="lg:grid lg:grid-cols-2 flex flex-col items-center bg-[#ececece6] py-10 mt-6 md:mt-20 lg:px-20 px-10">
        <div>
          <h1 className="service-line text-gray2">CONTACT US</h1>
          <h1 className="text-gray text-4xl">Call Now</h1>
          <h4 className="text-gray2 mt-8">Call: +91 9494476996</h4>
          <h4 className="text-gray2 mt-4">Email:support@surajenterprise.com</h4>
          <h4 className="text-gray2 mt-4">
            Behind KPHB Metro, Hyderabad. 
          </h4>
        </div>
        <div className="bg-white grid px-8 py-20 banner-input lg:mt-0 w-full shadow-lg">
          <input type="text" placeholder="Your Name" />
          <input type="text" placeholder="Phone Number" />
          <input type="text" placeholder="Email Address" />
          {/* <input type="text" placeholder="Your Message" /> */}
          <textarea placeholder="Your Message" rows="3"></textarea>
          <button className="text-start btn-gradient text-white px-6 py-2 w-[150px] rounded-full">
            Submit
          </button>
        </div>
      </div>
    </section>
  );
};

export default Contact;
