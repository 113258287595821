import React, { useRef, useState } from "react";
import {
  plan1,
  plan2,
  plan3,
  plan4,
  plan5,
  plan6,
  plan7,
  plan8,
  plan9,
} from "./data";

const Pricing = ({ priceRef }) => {
  return (
    <section ref={priceRef}>
      <div className="text-center lg:px-20 px-10 py-10">
        <h1 className="about-line text-gray2">BULK SMS PRICING</h1>
        {/* PRICE 1 */}
        <h1 className="text-gray text-4xl">
          Tran/PromoDND SMS - Standard Route
        </h1>
        <div className="lg:grid lg:grid-cols-4 md:grid md:grid-cols-2 gap-8 flex flex-col text-center mt-4">
          <Card plan={plan1} active={3} />
        </div>

        {/* PRICE 2 */}
        <h1 className="text-gray text-4xl mt-6 md:mt-20">
          Transactional - High Priority Route - Bulk SMS With API -
        </h1>
        <div className="lg:grid lg:grid-cols-4 md:grid md:grid-cols-2 gap-8 flex flex-col text-center mt-4">
          <Card plan={plan2} active={3} />
        </div>

        {/* PRICE 3 */}
        <h1 className="text-gray text-4xl mt-6 md:mt-20">
          Promotional SMS / Sim Base Sms
        </h1>
        <div className="lg:grid lg:grid-cols-4 md:grid md:grid-cols-2 gap-8 flex flex-col text-center mt-4">
          <Card plan={plan3} active={3} />
        </div>

        {/* PRICE 4 */}
        <h1 className="text-gray text-4xl mt-6 md:mt-20">Whats App SMS</h1>
        <div className="lg:grid lg:grid-cols-4 md:grid md:grid-cols-2 gap-8 flex flex-col text-center mt-4">
          <Card plan={plan4} active={4} />
          <Card plan={plan5} active={3} />
        </div>

        {/* PRICE 5 */}
        <h1 className="text-gray text-4xl mt-6 md:mt-20">Voice Calls SMS</h1>
        <div className="lg:grid lg:grid-cols-4 md:grid md:grid-cols-2 gap-8 flex flex-col text-center mt-4">
          <Card plan={plan6} active={4} />
          <Card plan={plan7} active={2} />
        </div>

        {/* PRICE 6 */}
        <h1 className="text-gray text-4xl mt-6 md:mt-20">
          Sim Based Bulk SMS - Standard Route
        </h1>
        <div className="lg:grid lg:grid-cols-4 md:grid md:grid-cols-2 gap-8 flex flex-col text-center mt-4">
          <Card plan={plan8} active={4} />
          <Card plan={plan9} active={2} />
        </div>
      </div>
    </section>
  );
};

export default Pricing;

function Card({ active, plan }) {
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleClick = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  return (
    <>
      {plan?.map((data, i) => {
        return (
          <div className="relative">
            <div
              className={`border-[#f1f1f1] border mt-4 p-8 relative ${
                active === data.id && "price-gradient"
              }`}>
              <h1 className="text-gray font-medium text-lg">{data.plan}</h1>
              <h1 className="text-3xl text-gray mb-4">&#x20B9; {data.price}</h1>
              <h5 className="text-gray2 mt-4">{data.p1}</h5>
              <h5 className="text-gray2 mt-4">{data.p2}</h5>
              <h5 className="text-gray2 mt-4">{data.p3}</h5>
              <h5 className="text-gray2 mt-4">{data.p4}</h5>
              <h5 className="text-gray2 mt-4">{data.p5}</h5>
              <h5 className="text-gray2 mt-4">{data.p6}</h5>
              <h5 className="text-gray2 mt-4">{data.p7}</h5>
              <h5 className="text-gray2 mt-4">{data.p8}</h5>
              <button
                className="border-blue border rounded-full text-blue px-6 py-2 mt-4 text-md font-medium"
                style={
                  active === data.id
                    ? { backgroundColor: "#fff", border: "none" }
                    : { backgroundColor: "transparent" }
                }
                onClick={() => {
                  handleClick(i);
                  setShow(true);
                }}>
                Buy Now
              </button>
              {data.new === true && (
                <div className="bg-blue absolute flex justify-center items-center px-4 py-2 rounded-tl-full rounded-bl-full right-0 top-10">
                  <span className="text-white font-medium text-[0.6rem]">
                    NEW
                  </span>
                </div>
              )}
            </div>

            {selected === i ? (
              <div
                className={`absolute top-[150px] bg-white p-2 border border-[#ccc] ${
                  show === true ? "flex" : "hidden"
                }`}
                style={{ height: "calc(100% - 150px)" }}>
                <div className="price-input text-start">
                  <h1 className="text-xl font-medium my-2">
                    Enter your information
                  </h1>
                  <input type="text" placeholder="Enter Your Name" />
                  <input type="email" placeholder="Enter Your Email" />
                  <input type="number" placeholder="Enter Your Mobile No." />
                  <button className="bg-green text-white px-4 py-[2px] rounded-full">
                    Pay Now
                  </button>
                  <button
                    className="bg-green text-white px-4 py-[2px] rounded-full ml-2"
                    onClick={() => setShow(false)}>
                    Cancel
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        );
      })}
    </>
  );
}
